var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": _vm.title,
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "130px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "开盘活动：",
      "prop": "openQuotationId",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "size": "medium"
    },
    on: {
      "change": _vm.getMarketActivity
    },
    model: {
      value: _vm.formData.openQuotationId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "openQuotationId", $$v);
      },
      expression: "formData.openQuotationId"
    }
  }, _vm._l(_vm.activityList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "类型:",
      "prop": "houseType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "small"
    },
    model: {
      value: _vm.formData.houseType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseType", $$v);
      },
      expression: "formData.houseType"
    }
  }, _vm._l(_vm.typeList, function (item, index) {
    return _c('el-radio-button', {
      key: index,
      attrs: {
        "label": item.a
      }
    }, [_vm._v(" " + _vm._s(item.b) + " ")]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "楼栋：",
      "prop": "buildingName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-autocomplete', {
    staticClass: "width",
    attrs: {
      "size": "medium",
      "fetch-suggestions": _vm.querySearch,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.buildingName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "buildingName", $$v);
      },
      expression: "formData.buildingName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "单元：",
      "prop": "unitName"
    }
  }, [_c('el-autocomplete', {
    staticClass: "width",
    attrs: {
      "size": "medium",
      "fetch-suggestions": _vm.querySearch2,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.unitName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "unitName", $$v);
      },
      expression: "formData.unitName"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("单元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "楼层：",
      "prop": "floorName"
    }
  }, [_c('el-autocomplete', {
    staticClass: "width",
    attrs: {
      "size": "medium",
      "fetch-suggestions": _vm.querySearch3,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.floorName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "floorName", $$v);
      },
      expression: "formData.floorName"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("楼")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "房号：",
      "prop": "houseNo",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "width",
    attrs: {
      "size": "medium",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.houseNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseNo", $$v);
      },
      expression: "formData.houseNo"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("号")])], 2)], 1), _vm.formData.houseType != 'OFFICE' && _vm.formData.houseType != 'SHOPS' ? _c('el-form-item', {
    attrs: {
      "label": "室厅卫：",
      "prop": "bedRoomQuantity",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('div', {
    staticClass: "x aic"
  }, [_c('el-input', {
    staticClass: "flex1",
    attrs: {
      "placeholder": "请输入",
      "disabled": !!_vm.formData.houseLayoutId
    },
    model: {
      value: _vm.formData.bedRoomQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "bedRoomQuantity", $$v);
      },
      expression: "formData.bedRoomQuantity"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("室")])], 2), _c('el-input', {
    staticClass: "flex1",
    attrs: {
      "placeholder": "请输入",
      "disabled": !!_vm.formData.houseLayoutId
    },
    model: {
      value: _vm.formData.livingRoomQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "livingRoomQuantity", $$v);
      },
      expression: "formData.livingRoomQuantity"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("厅")])], 2), _c('el-input', {
    staticClass: "flex1",
    attrs: {
      "placeholder": "请输入",
      "disabled": !!_vm.formData.houseLayoutId
    },
    model: {
      value: _vm.formData.restRoomQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "restRoomQuantity", $$v);
      },
      expression: "formData.restRoomQuantity"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("卫")])], 2)], 1)]) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "关联户型：",
      "prop": "houseLayoutId"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "size": "medium",
      "placeholder": "请选择"
    },
    model: {
      value: _vm.formData.houseLayoutId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseLayoutId", $$v);
      },
      expression: "formData.houseLayoutId"
    }
  }, _vm._l(_vm.houseLayoutList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "装修情况：",
      "prop": "remodelling"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.remodelling,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remodelling", $$v);
      },
      expression: "formData.remodelling"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "建筑面积：",
      "prop": "buildingArea",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.buildingArea,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "buildingArea", $$v);
      },
      expression: "formData.buildingArea"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "实得面积：",
      "prop": "actualArea",
      "rules": [_vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.actualArea,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "actualArea", $$v);
      },
      expression: "formData.actualArea"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "优惠前总价：",
      "prop": "discountBeforePrice",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.discountBeforePrice,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "discountBeforePrice", $$v);
      },
      expression: "formData.discountBeforePrice"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("万元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "优惠后总价：",
      "prop": "discountAfterPrice",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('div', [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.discountAfterPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "discountAfterPrice", $$v);
      },
      expression: "formData.discountAfterPrice"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("万元")])], 2), _c('p', {
    staticClass: "fs13 color9 lh24"
  }, [_vm._v("如果无优惠，与原价保存一致即可")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "营销活动：",
      "prop": "promotionalIds"
    }
  }, [_c('div', _vm._l(_vm.marketActivityList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticClass: "width hidden",
      attrs: {
        "label": item.id
      },
      model: {
        value: _vm.formData.promotionalIds,
        callback: function callback($$v) {
          _vm.$set(_vm.formData, "promotionalIds", $$v);
        },
        expression: "formData.promotionalIds"
      }
    }, [_c('p', [_vm._v(" " + _vm._s(item.giftBagName) + " "), _c('span', {
      staticClass: "red"
    }, [_vm._v("¥" + _vm._s(item.giftBagPrice))])])]);
  }), 1)]), _c('el-form-item', {
    attrs: {
      "label": "销售状态:",
      "prop": "salesStatus",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "small"
    },
    model: {
      value: _vm.formData.salesStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "salesStatus", $$v);
      },
      expression: "formData.salesStatus"
    }
  }, _vm._l(_vm.stateList, function (item, index) {
    return _c('el-radio-button', {
      key: index,
      attrs: {
        "label": item.a
      }
    }, [_vm._v(" " + _vm._s(item.b) + " ")]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "描述：",
      "prop": "description"
    }
  }, [_c('div', [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 3,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)])], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }