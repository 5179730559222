<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="value"
    center
    width="600px"
    :before-close="close"
    class="batchImport"
  >
    <div slot="title" class="title fs18 bold x aic">
      批量导入房源
    </div>
    <div class="content" v-loading="loading">
      <el-steps direction="vertical" :active="step">
        <el-step>
          <div slot="title" class="pointer blue" @click="downTemplate">
            下载《房源批量导入模板》
          </div>
          <div slot="description" class="color9 fs14">
            为了保障信息被有效导入，请遵循模板要求填写相关数据
          </div>
        </el-step>
        <el-step>
          <div slot="title" class="color3">
            选择所属开盘活动
          </div>
          <div slot="description" class="color9 fs14">
            <el-select
              size="medium"
              v-model="openQuotationId"
              @change="step = 2"
            >
              <el-option
                v-for="(item, index) in activityList"
                :label="item.activityName"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </el-step>
        <el-step>
          <div slot="title" class="color3">
            导入编辑好的Excel文档
          </div>
          <div slot="description">
            <p class="color9 fs14 h28">
              单个文档线索条数不超过2000条
            </p>
            <p class="h28">
              <span class="pointer blue bold2 fs16" @click="showFile">
                选择文件
              </span>
            </p>
            <p class="orange fs14 h28">{{ filename }}</p>
            <el-button
              type="primary"
              size="small"
              @click="importHouse"
              style="width:120px"
              :disabled="!file"
            >
              导入
            </el-button>
            <input
              ref="file"
              type="file"
              class="none"
              @change="chooseFile"
              accept=".xls"
            />
          </div>
        </el-step>
        <el-step>
          <div slot="title" class="x aic fs 16">
            <p class="color3">导入结果：</p>
            <p class="color9" v-if="step !== 4">等待导入</p>
            <p class="green" v-else-if="info.success">导入成功</p>
            <p class="red" v-else>导入失败</p>
          </div>
          <div slot="description" class="color9 fs14" v-if="step === 4">
            <p class="fs14 h28">
              成功导入
              <span class="green">{{ info.successCount }}</span> 条，导入失败
              <span class="red">{{ info.failCount }}</span> 条
              <span v-if="info.failCount">{{ info.errorMessage }}</span>
            </p>
            <el-button
              v-if="!info.success"
              type="primary"
              size="small"
              @click="exportError"
              style="width:120px"
            >
              导出错误数据
            </el-button>
          </div>
        </el-step>
      </el-steps>
    </div>
  </el-dialog>
</template>

<script>
import qs from "querystring";
import { importHouse } from "@/api/opening";
import { getOpeningList } from "@/api/openQuotationActivity";
export default {
  props: {
    value: {
      //显示弹出框
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filename: "选择的文档名称.xls",
      file: null,
      loading: false,
      step: 0,
      info: {},
      openQuotationId: "",
      activityList: []
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    value(e) {
      if (e) {
        this.init();
      }
    }
  },
  created() {
    this.getOpeningList();
  },
  methods: {
    init() {
      this.filename = "选择的文档名称.xls";
      this.file = null;
      this.step = 0;
      this.openQuotationId = "";
      this.info = {};
      if (this.$refs.file) {
        this.$refs.file.value = null;
      }
    },
    async getOpeningList() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.activityType == 0) {
            arr.push(e);
          }
        });
        this.activityList = arr;
      }
    },
    close() {
      //关闭弹出框
      this.$emit("input", false);
    },
    refresh() {
      this.$emit("refresh");
    },
    showFile() {
      this.$refs.file.click();
    },
    chooseFile(e) {
      let file = e.target.files[0];
      let filename = e.target.value;
      let filenames = filename.split("\\");
      filename = filenames[filenames.length - 1];
      this.filename = filename;
      this.file = file;
      this.step = 3;
    },
    exportError() {
      window.location.href = this.info.failedDownLoadUrl;
    },
    downTemplate() {
      let url = `${this.$BASE_URL}/api/v1/platform/building/downloadHouseTemplate`;
      let query = qs.stringify({
        userId: this.userInfo.userId,
        token: this.userInfo.token
      });
      window.location.href = `${url}?${query}`;
      this.step = 1;
    },
    async importHouse() {
      const { file, estateId, openQuotationId } = this;
      let query = {
        file
      };
      let params = {
        estateId,
        openQuotationId
      };
      this.loading = true;
      const data = await importHouse(params, query);
      this.loading = false;
      if (data) {
        this.$showSuccess("操作成功");
        this.info = data;
        this.step = 4;
        this.refresh();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.batchImport {
  .title {
    height: 50px;
    padding: 0 25px;
    border-bottom: 1px solid #dddddd;
  }
  .content {
    padding: 30px 20px;
  }
}
</style>

<style lang="scss">
.batchImport {
  .el-dialog__header,
  .el-dialog__footer,
  .el-dialog__body {
    padding: 0;
  }
  .el-step__main {
    padding-bottom: 40px;
  }
}
</style>
