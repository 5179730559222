<template>
  <div class="openingBlock dao-main pd20 height oauto y flex1">
    <div class="header x3 aic">
      <p class="total">
        <span class="mr10">{{ titleNames.join("-") }}</span>
        共<span class="red">{{ total }}</span
        >套房源
      </p>
      <div class="x aic" v-show="!isEdit && blockList.length">
        <div class="x aic mr20">
          <el-select
            v-model="flootNo"
            clearable
            size="medium"
            placeholder="请选择楼层"
            style="width: 160px;"
          >
            <div slot="prefix" class="height x aic">
              <i class="iconfont colo3 fs16">&#xe647;</i>
            </div>
            <el-option
              v-for="item in floorNameList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-button size="medium" @click="scrollInto">
            直达
          </el-button>
        </div>
        <el-button icon="el-icon-refresh-left" size="medium" @click="getData">
          刷新房源
        </el-button>
        <el-select
          class="mw20 w150"
          size="medium"
          @change="getData"
          v-model="queryData.sort"
        >
          <el-option
            v-for="(item, index) in sortList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-button type="primary" size="medium" @click="chooseItem">
          批量操作
        </el-button>
      </div>
      <el-button
        v-show="isEdit"
        type="primary"
        size="medium"
        @click="quitChoose"
      >
        退出选择
      </el-button>
    </div>
    <div class="flex1 oauto">
      <div class="pt20" v-for="(l, i) in blockList" :key="i" :id="l.floorName">
        <p class="fs16 mb8" v-if="l.floorName">
          <i class="iconfont">&#xe647;</i>
          {{ l.floorName }}
        </p>
        <div class="block-box">
          <itemBlock
            v-for="(item, index) in l.houseItems"
            :key="index"
            :options="item"
            :isEdit="isEdit"
            :checked="findId(item.id)"
            @itemCheck="itemCheck"
            @click.native="showInfo(item)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="blockList.length"
      class="aic"
      :class="{ x3: isEdit, x2: !isEdit }"
    >
      <div class="x aic" v-show="isEdit">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8"
          size="medium"
          @change="houseBatchSalesStatus"
          v-model="salesStatus"
          placeholder="批量销控"
        >
          <el-option
            v-for="(item, index) in stateList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8 x1 aic overflow"
          size="medium"
          @change="houseBatchRelationHouseLayout"
          v-model="houseLayoutId"
          placeholder="关联户型"
        >
          <el-option :value="null" class="x1 aic">
            <p class="red">取消关联</p>
          </el-option>
          <el-option
            v-for="(item, index) in houseLayoutList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8"
          size="medium"
          @change="houseBatchAvailable"
          v-model="available"
          placeholder="上架/下架"
        >
          <el-option
            v-for="(item, index) in availableList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-show="activetyId"
          :disabled="!idList.length"
          multiple
          collapse-tags
          class="w120 ml8 x1 aic overflow"
          size="medium"
          @change="houseBatchPromotional"
          v-model="promotionalIdList"
          placeholder="设置营销"
        >
          <el-option
            v-for="(item, index) in marketList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8"
          size="medium"
          @change="houseBatchOpenQuotation"
          v-model="openQuotationId"
          placeholder="关联开盘"
        >
          <el-option
            v-for="(item, index) in activityList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-button
          style="margin-left:8px;"
          size="medium "
          :disabled="!idList.length"
          @click="houseBatchDelete"
        >
          <span class="red">批量删除</span>
        </el-button>
      </div>
    </div>
    <el-drawer
      size="375px"
      :visible.sync="showInfoFlag"
      :with-header="false"
      :show-close="false"
    >
      <itemInfo
        v-if="showInfoFlag"
        :id="currentInfo.id"
        @openEdit="openEdit"
        @refresh="refresh"
      />
    </el-drawer>
    <editForm
      :visible.sync="showEdit"
      :id="currentInfo.id"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  housePage,
  houseSortTypeList,
  parkingPlaceSalesStatusList,
  houseBatchSalesStatus,
  houseBatchAvailable,
  houseBatchOpenQuotation,
  houseBatchPromotional,
  houseBatchDelete,
  getHouseTypeList,
  houseBatchRelationHouseLayout
} from "@/api/opening";
import { getOpeningList } from "@/api/openQuotationActivity";
import { getMarketActivity } from "@/api/marketActivity";
import itemBlock from "./itemBlock";
import itemInfo from "./itemInfo";
import editForm from "./editForm";

export default {
  props: {
    titleNames: {
      type: Array,
      default() {
        return [];
      }
    },
    activetyId: {
      type: [String, Number],
      default: ""
    },
    refreshFlag: {
      type: Boolean,
      default: false
    },
    optionsQuery: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    itemBlock,
    itemInfo,
    editForm
  },
  data() {
    return {
      flootNo: "",
      value: "",
      blockList: [],
      isEdit: false,
      showInfoFlag: false,
      currentInfo: {},
      queryData: {
        sort: "ID_ASC"
      },
      salesStatus: "",
      available: "",
      openQuotationId: "",
      promotionalIdList: [],
      idList: [],
      sortList: [],
      stateList: [],
      activityList: [],
      marketList: [],
      total: 0,
      showEdit: false,
      availableList: [
        {
          name: "上架",
          id: true
        },
        {
          name: "下架",
          id: false
        }
      ],
      isIndeterminate: false,
      checkAll: false,
      houseLayoutList: [],
      houseLayoutId: ""
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    },
    floorNameList() {
      let list = [];
      this.blockList.forEach(e => {
        if (e.floorName) {
          list.push(e.floorName);
        }
      });
      return list;
    }
  },
  watch: {
    optionsQuery: {
      deep: true,
      handler(e) {
        this.queryData = {
          ...this.queryData,
          ...e
        };
        this.getData();
      }
    },
    idList: {
      deep: true,
      handler() {
        this.changeCheckState();
      }
    },
    refreshFlag() {
      this.getData();
    },
    activetyId() {
      this.getMarketActivity();
    }
  },
  created() {
    this.parkingPlaceSalesStatusList();
    this.houseSortTypeList();
    this.getOpeningList();
    this.getHouseTypeList();
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    initCheck() {
      this.openQuotationId = "";
      this.promotionalIdList = [];
      this.available = "";
      this.houseLayoutId = "";
      this.salesStatus = "";
    },
    scrollInto() {
      let id = this.flootNo;
      if (!id) {
        return;
      }
      let dom = document.getElementById(id);
      dom.scrollIntoView();
    },
    handleCheckAllChange(val) {
      let idList = [];
      if (val) {
        this.blockList.forEach(el => {
          if (el.houseItems) {
            el.houseItems.forEach(el2 => {
              idList.push(el2.id);
            });
          }
        });
      }
      this.idList = idList;
      this.initCheck();
    },
    changeCheckState() {
      const { blockList, idList } = this;
      if (idList.length) {
        if (idList.length < blockList.length) {
          this.checkAll = false;
          this.isIndeterminate = true;
        } else {
          this.isIndeterminate = false;
          this.checkAll = true;
        }
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
    },
    async getHouseTypeList() {
      let query = {
        estateId: this.estateId
      };
      const res = await getHouseTypeList(query);
      if (res) {
        let list = res || [];
        this.houseLayoutList = list.map(e => {
          const {
            bedRoomQuantity,
            livingRoomQuantity,
            restRoomQuantity,
            name
          } = e;
          e.name = `${name} ${bedRoomQuantity}室${livingRoomQuantity}厅${restRoomQuantity}卫`;
          return e;
        });
      }
    },
    async parkingPlaceSalesStatusList() {
      const res = await parkingPlaceSalesStatusList();
      let list = res || [];
      this.stateList = this.$filterListTOName(list, { id: "a", name: "b" });
    },
    async getData() {
      if (!this.queryData.buildingLayoutId) {
        return;
      }
      const res = await housePage(this.queryData);
      if (res) {
        const { total, list } = res;
        this.total = total;
        this.blockList = list;
      }
    },
    showInfo(info) {
      if (this.isEdit) {
        return;
      }
      this.currentInfo = info;
      this.showInfoFlag = true;
    },
    async houseSortTypeList() {
      const res = await houseSortTypeList();
      let list = res || [];
      this.sortList = this.$filterListTOName(list, { id: "a", name: "b" });
    },
    async getOpeningList() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.activityType == 0) {
            arr.push(e);
          }
        });
        this.activityList = this.$filterListTOName(arr, {
          id: "id",
          name: "activityName"
        });
      }
    },
    async getMarketActivity() {
      let id = this.activetyId;
      if (!id) {
        return;
      }
      let query = {
        status: 1
      };
      const res = await getMarketActivity(id, query);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.status == 1) {
            arr.push(e);
          }
        });
        this.marketList = this.$filterListTOName(arr, {
          id: "id",
          name: "giftBagName"
        });
      }
    },
    quitChoose() {
      this.isEdit = false;
      this.idList = [];
      this.initCheck();
    },
    async houseBatchSalesStatus(e) {
      if (!e && e !== false) {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        houseIdList: list,
        salesStatus: e
      };
      const res = await houseBatchSalesStatus(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.refresh();
      }
    },
    async houseBatchAvailable(e) {
      if (!e && e !== false) {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        houseIdList: list,
        available: e
      };
      const res = await houseBatchAvailable(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.refresh();
      }
    },
    async houseBatchOpenQuotation(e) {
      if (!e && e !== false) {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        houseIdList: list,
        openQuotationId: e
      };
      const res = await houseBatchOpenQuotation(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.refresh();
      }
    },
    async houseBatchPromotional() {
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        houseIdList: list,
        promotionalIdList: this.promotionalIdList
      };
      const res = await houseBatchPromotional(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.refresh();
      }
    },
    async houseBatchRelationHouseLayout(e) {
      if (e == "") {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        houseIdList: list,
        houseLayoutId: this.houseLayoutId
      };
      const res = await houseBatchRelationHouseLayout(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.refresh();
      }
    },
    async houseBatchDelete() {
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        houseIdList: list
      };
      this.$showConfirm(async () => {
        const res = await houseBatchDelete(query);
        if (res) {
          this.$showSuccess("操作成功");
          this.refresh();
        }
      }, "是否确认删除该数据?");
    },
    openEdit() {
      this.showInfoFlag = false;
      this.showEdit = true;
    },
    chooseItem() {
      this.isEdit = true;
      this.idList = [];
    },
    itemCheck(id, checked) {
      let set = new Set(this.idList);
      if (checked) {
        set.add(id);
      } else {
        set.delete(id);
      }
      this.idList = [...set];
      this.initCheck();
    },
    findId(id) {
      let flag = false;
      flag = this.idList.find(e => e == id);
      return !!flag;
    }
  }
};
</script>

<style lang="scss" scoped>
.openingBlock {
  padding: 0 20px;
  flex: 1;
  .header {
    border-bottom: 1px solid #eee;
    height: 60px;
    .total {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .block-box {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  ::v-deep {
    .el-select {
      overflow: hidden;
    }
    .el-select__tags {
      flex-wrap: nowrap;
    }
  }
}
</style>
