<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="500px"
  >
    <el-form :model="formData" class="form" ref="form" label-width="130px">
      <el-form-item
        label="开盘活动："
        prop="openQuotationId"
        :rules="[$formRules.required()]"
      >
        <el-select
          size="medium"
          v-model="formData.openQuotationId"
          style="width: 100%"
          @change="getMarketActivity"
        >
          <el-option
            v-for="(item, index) in activityList"
            :label="item.activityName"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="类型:"
        prop="houseType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.houseType" size="small">
          <el-radio-button
            :label="item.a"
            v-for="(item, index) in typeList"
            :key="index"
          >
            {{ item.b }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="楼栋："
        prop="buildingName"
        :rules="[$formRules.required()]"
      >
        <el-autocomplete
          class="width"
          size="medium"
          v-model="formData.buildingName"
          :fetch-suggestions="querySearch"
          placeholder="请输入"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="单元：" prop="unitName">
        <el-autocomplete
          class="width"
          size="medium"
          v-model="formData.unitName"
          :fetch-suggestions="querySearch2"
          placeholder="请输入"
        >
          <template slot="append">单元</template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="楼层：" prop="floorName">
        <el-autocomplete
          class="width"
          size="medium"
          v-model="formData.floorName"
          :fetch-suggestions="querySearch3"
          placeholder="请输入"
        >
          <template slot="append">楼</template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item
        label="房号："
        prop="houseNo"
        :rules="[$formRules.required()]"
      >
        <el-input
          class="width"
          size="medium"
          v-model="formData.houseNo"
          placeholder="请输入"
        >
          <template slot="append">号</template>
        </el-input>
      </el-form-item>
      <el-form-item
        v-if="formData.houseType != 'OFFICE' && formData.houseType != 'SHOPS'"
        label="室厅卫："
        prop="bedRoomQuantity"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <div class="x aic">
          <el-input
            class="flex1"
            v-model="formData.bedRoomQuantity"
            placeholder="请输入"
            :disabled="!!formData.houseLayoutId"
          >
            <template slot="append">室</template>
          </el-input>
          <el-input
            class="flex1"
            v-model="formData.livingRoomQuantity"
            placeholder="请输入"
            :disabled="!!formData.houseLayoutId"
          >
            <template slot="append">厅</template>
          </el-input>
          <el-input
            class="flex1"
            v-model="formData.restRoomQuantity"
            placeholder="请输入"
            :disabled="!!formData.houseLayoutId"
          >
            <template slot="append">卫</template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="关联户型：" prop="houseLayoutId">
        <el-select
          clearable
          size="medium"
          v-model="formData.houseLayoutId"
          placeholder="请选择"
          style="width:100%"
        >
          <el-option
            v-for="(item, index) in houseLayoutList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="装修情况：" prop="remodelling">
        <el-input v-model="formData.remodelling" placeholder="请输入">
        </el-input>
      </el-form-item>
      <el-form-item
        label="建筑面积："
        prop="buildingArea"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <el-input v-model="formData.buildingArea" placeholder="请输入">
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="实得面积："
        prop="actualArea"
        :rules="[$formRules.number]"
      >
        <el-input v-model="formData.actualArea" placeholder="请输入">
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="优惠前总价："
        prop="discountBeforePrice"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <el-input v-model="formData.discountBeforePrice" placeholder="请输入">
          <template slot="append">万元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="优惠后总价："
        prop="discountAfterPrice"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <div>
          <el-input v-model="formData.discountAfterPrice" placeholder="请输入">
            <template slot="append">万元</template>
          </el-input>
          <p class="fs13 color9 lh24">如果无优惠，与原价保存一致即可</p>
        </div>
      </el-form-item>
      <el-form-item label="营销活动：" prop="promotionalIds">
        <div>
          <el-checkbox
            v-model="formData.promotionalIds"
            v-for="(item, index) in marketActivityList"
            :key="index"
            :label="item.id"
            class="width hidden"
          >
            <p>
              {{ item.giftBagName }}
              <span class="red">¥{{ item.giftBagPrice }}</span>
            </p>
          </el-checkbox>
        </div>
      </el-form-item>
      <el-form-item
        label="销售状态:"
        prop="salesStatus"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.salesStatus" size="small">
          <el-radio-button
            :label="item.a"
            v-for="(item, index) in stateList"
            :key="index"
          >
            {{ item.b }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="描述：" prop="description">
        <div>
          <el-input
            type="textarea"
            :rows="3"
            v-model="formData.description"
            placeholder="请输入"
          >
          </el-input>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  houseLayoutTree,
  houseTypeList,
  houseSalesStatusList,
  houseBasicInfo,
  mergeHouse,
  getHouseTypeList
} from "@/api/opening";

import { getOpeningList } from "@/api/openQuotationActivity";
import { getMarketActivity } from "@/api/marketActivity";

const formData = {
  actualArea: null,
  bedRoomQuantity: null,
  buildingArea: null,
  buildingName: "",
  description: "",
  discountAfterPrice: null,
  discountBeforePrice: null,
  floorName: "",
  houseNo: "",
  houseType: "RESIDENCE",
  id: null,
  livingRoomQuantity: null,
  openQuotationId: null,
  promotionalIds: [],
  remodelling: "",
  restRoomQuantity: null,
  salesStatus: "AVAILABLE_SALE",
  unitName: "",
  houseLayoutId: ""
};
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      formData: {},
      typeList: [],
      stateList: [],
      activityList: [],
      marketActivityList: [],
      checked: false,
      houseLayoutTreeData: [],
      houseLayoutList: []
    };
  },
  computed: {
    title() {
      let title = "创建";
      if (this.id) {
        title = "编辑";
      }
      return title + "房源";
    },
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
    "formData.houseLayoutId"(e) {
      if (e) {
        let item = this.houseLayoutList.find(el => e == el.id);
        const { bedRoomQuantity, livingRoomQuantity, restRoomQuantity } = item;
        this.formData.bedRoomQuantity = bedRoomQuantity;
        this.formData.livingRoomQuantity = livingRoomQuantity;
        this.formData.restRoomQuantity = restRoomQuantity;
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    async init() {
      const { id } = this;
      if (id) {
        await this.getDetail();
        this.getMarketActivity();
      } else {
        this.formData = this.$deepClone(formData);
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
      this.getOpeningList();
      this.houseLayoutTree();
      this.houseTypeList();
      this.houseSalesStatusList();
      this.getHouseTypeList();
    },
    async houseLayoutTree() {
      let query = {
        estateId: this.estateId
      };
      const res = await houseLayoutTree(query);
      if (res) {
        this.houseLayoutTreeData = res || [];
      }
    },
    querySearch(queryString, cb) {
      let results = this.houseLayoutTreeData.map(e => {
        return {
          value: e.name
        };
      });
      cb(results);
    },
    querySearch2(queryString, cb) {
      let list = [];
      this.houseLayoutTreeData.forEach(e => {
        if (e.name == this.formData.buildingName) {
          if (e.children && e.children.length) {
            list = e.children.filter(el => el.layoutType == "BUILDING_UNIT");
          }
        }
      });
      let results = list.map(e => {
        return {
          value: e.name
        };
      });
      cb(results);
    },
    querySearch3(queryString, cb) {
      let list = [];
      this.houseLayoutTreeData.forEach(e => {
        if (e.name == this.formData.buildingName) {
          if (e.children && e.children.length) {
            if (this.formData.unitName) {
              e.children.forEach(e2 => {
                if (e2.name == this.formData.unitName) {
                  if (e2.children && e2.children.length) {
                    list = e2.children;
                  }
                }
              });
            } else {
              list = e.children.filter(el => el.layoutType == "BUILDING_FLOOR");
            }
          }
        }
      });
      let results = list.map(e => {
        return {
          value: e.name
        };
      });
      cb(results);
    },
    async getOpeningList() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.activityType == 0) {
            arr.push(e);
          }
        });
        this.activityList = arr;
      }
    },
    async getHouseTypeList() {
      let query = {
        estateId: this.estateId
      };
      const res = await getHouseTypeList(query);
      if (res) {
        let list = res || [];
        this.houseLayoutList = list.map(e => {
          const {
            bedRoomQuantity,
            livingRoomQuantity,
            restRoomQuantity,
            name
          } = e;
          e.name = `${name} ${bedRoomQuantity}室${livingRoomQuantity}厅${restRoomQuantity}卫`;
          return e;
        });
      }
    },
    async getMarketActivity() {
      let id = this.formData.openQuotationId;
      if (!id) {
        return;
      }
      let query = {
        status: 1
      };
      const res = await getMarketActivity(id, query);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.status == 1) {
            arr.push(e);
          }
        });
        this.marketActivityList = arr;
      }
    },
    async houseTypeList() {
      const res = await houseTypeList();
      this.typeList = res || [];
    },
    async houseSalesStatusList() {
      const res = await houseSalesStatusList();
      this.stateList = res || [];
    },
    async getDetail() {
      const res = await houseBasicInfo(this.id);
      if (res) {
        this.formData = this.$deepClone(res);
        this.formData.discountAfterPrice = this.$calc.accMulti(
          res.discountAfterPrice,
          10000
        );
      }
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        let query = {
          estateId: this.estateId
        };
        const res = await mergeHouse(query, this.formData);
        if (res) {
          this.$showSuccess("操作成功");
          this.close();
          this.$emit("refresh");
        }
      });
    }
  }
};
</script>
