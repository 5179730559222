<template>
  <div class="item-box mb24">
    <div class="x3 aic h40 mb8" style="border-bottom: 1px solid #ccc;">
      <div class="x aic">
        <p class="fs16 bold">{{ options.title }}</p>
        <p class="color9 ml8">{{ options.title2 }}</p>
      </div>
      <p class="color9 pointer" @click="checkedList = []">
        <i class="el-icon-refresh-left"></i> 重置
      </p>
    </div>
    <el-checkbox-group :class="`fr${options.type}`" v-model="checkedList">
      <div v-for="(item, index) in options.list" :key="index" class="x3 aic">
        <div class="flex1 x3 aic">
          <div class="x aic">
            <el-checkbox :label="item.id" class="checkbox">
              {{ item.name }}
            </el-checkbox>
            <p class="fs14 ml4 color3">
              {{ item.name2 }}
            </p>
          </div>
          <p class="name2 fs14 color9">{{ item.num }}</p>
        </div>
        <div
          v-if="item.color"
          class="spot"
          :style="`background-color:${item.color};`"
        ></div>
      </div>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkedList: []
    };
  },
  watch: {
    checkedList: {
      deep: true,
      handler() {
        this.getChecked();
      }
    }
  },
  methods: {
    getChecked() {
      this.$emit("getChecked", this.checkedList);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-box {
  width: 100%;
  overflow: hidden;
  .fr1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
  }
  .fr2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  }
  .spot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
  }
  .checkbox {
    max-width: 180px;
    overflow: hidden;
  }
  .name2 {
    overflow: hidden;
    text-align: right;
  }
}
</style>
