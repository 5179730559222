var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info y3"
  }, [_c('div', [_c('div', {
    staticClass: "y aic",
    class: {
      color9: !_vm.buildingInfo.available
    }
  }, [_c('p', {
    staticClass: "fs20 bold lh40"
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.buildingName) + " " + _vm._s(_vm.buildingInfo.unitName && "-".concat(_vm.buildingInfo.unitName, "\u5355\u5143")) + " " + _vm._s(_vm.buildingInfo.floorName && "-".concat(_vm.buildingInfo.floorName, "\u697C")) + " " + _vm._s(_vm.buildingInfo.houseNo && "-".concat(_vm.buildingInfo.houseNo, "\u53F7\u623F")) + " ")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.available ? "上架" : "下架") + "中")])]), _c('div', {
    staticClass: "state y3",
    style: {
      'background-color': _vm.carStateObj.bgColor
    }
  }, [_c('div', {
    staticClass: "fs16 bold x3 aic"
  }, [_c('p', {
    staticClass: "flex1",
    style: {
      color: _vm.carStateObj.color
    }
  }, [_vm._v(" " + _vm._s(_vm.carStateObj.name) + " ")]), _c('p', {
    staticClass: "flex1 x1"
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.discountBeforePrice ? "".concat(_vm.buildingInfo.discountBeforePrice, "\u4E07\u5143") : "\u4EF7\u683C\u5F85\u5B9A") + " ")]), _c('p', {
    staticClass: "red flex1 x2"
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.discountAfterPrice ? "".concat(_vm.buildingInfo.discountAfterPrice, "\u4E07\u5143") : "\u4EF7\u683C\u5F85\u5B9A") + " ")])]), _vm._m(0)]), _c('div', [_vm.promotions.length ? _c('div', [_c('p', {
    staticClass: "bold lh40"
  }, [_vm._v("营销活动")]), _vm._l(_vm.promotions, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "pb16 bdb2"
    }, [_c('div', {
      staticClass: "x3 aic h28"
    }, [_c('p', {
      staticClass: "color9"
    }, [_vm._v("认购总额")]), _c('div', {
      staticClass: "x aic h28"
    }, [_c('p', {
      staticClass: "red"
    }, [_vm._v("¥")]), _c('p', {
      staticClass: "fs20 red"
    }, [_vm._v(" " + _vm._s(item.subscriptionTotalPrice ? "".concat(item.subscriptionTotalPrice, "\u4E07\u5143") : "\u4EF7\u683C\u5F85\u5B9A") + " ")])])]), _c('div', {
      staticClass: "x3 aic h28"
    }, [_c('p', {
      staticClass: "color9"
    }, [_vm._v("优惠活动")]), _c('div', {
      staticClass: "x aic h28"
    }, [_c('img', {
      staticClass: "icon20 mr10",
      attrs: {
        "src": require("../../../../assets/icon_libao_xiao.png")
      }
    }), _c('p', {
      staticClass: "red"
    }, [_vm._v(_vm._s(item.promotionalName))])])])]);
  })], 2) : _vm._e(), _c('div', [_c('p', {
    staticClass: "bold lh40"
  }, [_vm._v("基本信息")]), _vm.buildingInfo.id ? _c('div', {
    staticClass: "pb16 bdb2"
  }, [_vm.buildingInfo.openQuotationName ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("开盘活动:")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.openQuotationName))])]) : _vm._e(), _vm.buildingInfo.houseTypeName ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("房源类型:")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.houseTypeName))])]) : _vm._e(), _vm.buildingInfo.bedRoomQuantity ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("居室厅:")]), _c('p', [_vm._v(" " + _vm._s(_vm.buildingInfo.bedRoomQuantity) + "室" + _vm._s(_vm.buildingInfo.livingRoomQuantity) + "厅" + _vm._s(_vm.buildingInfo.restRoomQuantity) + "卫 ")])]) : _vm._e(), _vm.buildingInfo.houseLayoutName ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("关联户型:")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.houseLayoutName))])]) : _vm._e(), _vm.buildingInfo.remodelling ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("装修情况:")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.remodelling))])]) : _vm._e(), _vm.buildingInfo.buildingArea ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("建筑面积:")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.buildingArea) + "㎡")])]) : _vm._e(), _vm.buildingInfo.actualArea ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("实得面积:")]), _c('p', [_vm._v(_vm._s(_vm.buildingInfo.actualArea) + "㎡")])]) : _vm._e(), _c('div', {
    staticClass: "x3"
  }, [_c('p', {
    staticClass: "color9 lh28"
  }, [_vm._v("备注信息:")]), _c('p', {
    staticClass: "w240 lh28"
  }, [_vm._v(_vm._s(_vm.buildingInfo.description))])])]) : _vm._e(), _c('div', {
    staticClass: "pb16 bdb2"
  }, [_c('div', {
    staticClass: "x3 aic h40"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("收藏用户")]), _c('p', [_vm._v(_vm._s(_vm.collectionUsers.length) + "个")])]), _c('div', {
    staticClass: "user-list"
  }, _vm._l(_vm.collectionUsers, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "user-cell",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('el-popover', {
      attrs: {
        "placement": "top-start",
        "width": "220",
        "trigger": "hover"
      }
    }, [_c('div', [_c('p', [_vm._v("认购客户：" + _vm._s(item.name))]), _c('p', [_vm._v("联系电话：" + _vm._s(_vm._f("formatPhone")(item.cellphone)))])]), _c('img', {
      staticClass: "user-cell",
      attrs: {
        "slot": "reference",
        "src": item.avatarUrl
      },
      slot: "reference"
    })])], 1);
  }), 0)])]), _vm.subscriptionParkingBookingOrder.id ? _c('div', [_c('p', {
    staticClass: "bold lh40"
  }, [_vm._v("认购订单")]), _vm.subscriptionParkingBookingOrder.id ? _c('div', {
    staticClass: "pb16 bdb2"
  }, [_c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("认购单号")]), _c('p', [_c('svg-icon', {
    staticClass: "copy-icon pointer",
    attrs: {
      "icon-class": "copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.subscriptionParkingBookingOrder.orderNo);
      }
    }
  }), _vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.orderNo) + " ")], 1)]), _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("认购总额")]), _c('div', {
    staticClass: "x aic h28"
  }, [_c('p', {
    staticClass: "red"
  }, [_vm._v("¥")]), _vm.subscriptionParkingBookingOrder.orderTotalPrice ? _c('p', {
    staticClass: "fs20 red bold"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.orderTotalPrice) + "万元 ")]) : _c('p', {
    staticClass: "fs20 red bold"
  }, [_vm._v("价格待定")])])]), _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("优惠活动")]), _c('p', {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.giftBagName || (_vm.subscriptionParkingBookingOrder.orderTotalPriceDesc == "认购总额" ? "无" : _vm.subscriptionParkingBookingOrder.orderTotalPriceDesc)) + " ")])])]) : _vm._e(), _vm.subscriptionParkingBookingOrder.id ? _c('div', {
    staticClass: "pb16 bdb2 mt8"
  }, [_c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("认购金")]), _c('p', {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.subscriptionParkingBookingOrder.orderPrice)) + " ")])]), _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("支付方式")]), _c('p', [_vm._v(_vm._s(_vm.subscriptionParkingBookingOrder.orderWayDesc))])]), _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("认购时间")]), _c('p', [_vm._v(_vm._s(_vm.subscriptionParkingBookingOrder.inDate))])])]) : _vm._e(), _c('div', {
    staticClass: "pb16 bdb2 mt8"
  }, [_c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("认购人")]), _c('p', [_vm._v(_vm._s(_vm.subscriptionParkingBookingOrder.customerName))])]), _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("身份证号")]), _c('p', [_vm._v(_vm._s(_vm.subscriptionParkingBookingOrder.customerIdcard))])]), _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("手机号码")]), _c('p', [_vm._v(_vm._s(_vm.subscriptionParkingBookingOrder.customerPhone))])])])]) : _vm._e(), _vm.bidTargetInfo.biddingAmount ? _c('div', [_c('p', {
    staticClass: "bold lh40"
  }, [_vm._v("正在竞拍")]), _c('div', {
    staticClass: "pb16 bdb2"
  }, [_vm.bidTargetInfo.biddingAmount ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("起拍价:")]), _c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.bidTargetInfo.biddingAmount)))])]) : _vm._e(), _vm.bidTargetInfo.biddingStepAmount ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("加价幅度:")]), _c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.bidTargetInfo.biddingStepAmount)))])]) : _vm._e(), _vm.bidTargetInfo.delay ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("延时周期:")]), _c('p', [_vm._v(_vm._s(_vm.bidTargetInfo.delay) + "分钟")])]) : _vm._e(), _vm.bidTargetInfo.ensureAmount ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("保证金:")]), _c('p', [_vm._v("¥" + _vm._s(_vm._f("toThousands")(_vm.bidTargetInfo.ensureAmount)))])]) : _vm._e(), _vm.bidTargetInfo.startDate ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("开始时间:")]), _c('p', [_vm._v(_vm._s(_vm.bidTargetInfo.startDate))])]) : _vm._e(), _vm.bidTargetInfo.endDelayDate ? _c('div', {
    staticClass: "x3 aic h28"
  }, [_c('p', {
    staticClass: "color9"
  }, [_vm._v("结束时间:")]), _c('p', [_vm._v(_vm._s(_vm.bidTargetInfo.endDelayDate))])]) : _vm._e()])]) : _vm._e()])]), _c('div', {
    staticClass: "x aic mt24"
  }, [_c('el-button', {
    staticClass: "flex1",
    attrs: {
      "size": "medium"
    },
    on: {
      "click": _vm.houseBatchAvailable
    }
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.available ? "下架" : "上架") + " ")]), _c('el-button', {
    staticClass: "flex1",
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.openEdit
    }
  }, [_vm._v(" 编辑房源 ")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fs14 color6 x3 aic"
  }, [_c('p', {
    staticClass: "flex1"
  }, [_vm._v("销售状态")]), _c('p', {
    staticClass: "flex1 x1"
  }, [_vm._v("优惠前总价")]), _c('p', {
    staticClass: "flex1 x2"
  }, [_vm._v("优惠后总价")])]);

}]

export { render, staticRenderFns }