<template>
  <div class="x house">
    <div class="openingMenu dao-main pd20 mr10">
      <div class="header mb24">
        <div class="x mb16">
          <el-cascader
            style="width:100%"
            class="cascader"
            ref="areaTree"
            v-model="queryData.buildingLayoutId"
            :options="houseLayoutTreeData"
            :props="{
              label: 'name',
              value: 'id',
              emitPath: false,
              checkStrictly: true
            }"
            placeholder="楼栋"
            @change="getTreeNameById"
          ></el-cascader>
        </div>
        <el-input v-model="roomNo" placeholder="输入房号搜索" size="medium">
          <el-button slot="append" @click="search">
            搜索
          </el-button>
        </el-input>
      </div>
      <div>
        <menuItem
          :options="saleOptions"
          @getChecked="e => getChecked(e, 'salesStatusList')"
        />
        <menuItem
          :options="carOptions"
          @getChecked="e => getChecked(e, 'houseTypeList')"
        />
        <menuItem
          :options="activityOptions"
          @getChecked="e => getChecked(e, 'openQuotationIdList')"
        />
      </div>
      <div class="x3 aic">
        <el-button icon="el-icon-plus" size="medium" @click="showEdit = true">
          创建房源
        </el-button>
        <el-button
          icon="el-icon-upload2"
          type="primary"
          size="medium"
          @click="showImport = true"
        >
          批量导入房源
        </el-button>
      </div>
      <itemImport v-model="showImport" @refresh="refresh" />
      <editForm :visible.sync="showEdit" @refresh="refresh" />
    </div>
    <openingBlock
      :titleNames="titleNames"
      :refreshFlag="refreshFlag"
      :optionsQuery="queryData"
      :activetyId="activetyId"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { enumeration } from "@/config";
import { houseLayoutTree, houseFilter } from "@/api/opening";

import menuItem from "./components/menuItem";
import itemImport from "./components/itemImport";
import editForm from "./components/editForm";
import openingBlock from "./components/openingBlock";
const queryData = {
  buildingLayoutId: "",
  roomNo: "",
  salesStatusList: [], //销售状态
  houseTypeList: [], //车位类型
  openQuotationIdList: [] //开盘活动ID
};
export default {
  components: {
    openingBlock,
    menuItem,
    itemImport,
    editForm
  },
  data() {
    return {
      showImport: false,
      showEdit: false,
      queryData: JSON.parse(JSON.stringify(queryData)),
      roomNo: "",
      saleOptions: {
        title: "销售状态",
        type: 2,
        list: []
      },
      carOptions: {
        title: "房源类型",
        type: 2,
        list: []
      },
      activityOptions: {
        title: "开盘活动",
        title2: "可售房源/总房源数",
        type: 1,
        list: []
      },
      openingCarStateObj: enumeration.openingCarStateObj,
      houseLayoutTreeData: [],
      titleNames: [],
      refreshFlag: false,
      activetyId: ""
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.houseLayoutTree();
  },
  methods: {
    refresh() {
      this.houseFilter();
      this.refreshFlag = !this.refreshFlag;
    },
    search() {
      this.queryData.roomNo = this.roomNo;
    },
    getTreeNameById() {
      let tree = this.houseLayoutTreeData;
      let id = this.queryData.buildingLayoutId;
      let names = this.$getTreeNameById(tree, id, "name", true);
      this.titleNames = names;
      this.houseFilter();
    },
    async houseLayoutTree() {
      let query = {
        estateId: this.estateId
      };
      const res = await houseLayoutTree(query);
      if (res) {
        let list = res || [];
        list = list.map(e => {
          if (e.children && e.children.length) {
            e.children = e.children.filter(
              e => e.layoutType == "BUILDING_UNIT"
            );
            if (e.children && e.children.length) {
              e.children.forEach(e2 => {
                let nameLenth = e2.name.length;
                let unit = e2.name.substring(nameLenth - 2, nameLenth);
                if (unit != "单元") {
                  e2.name += "单元";
                }
                e2.children = null;
              });
            } else {
              e.children = null;
            }
          }
          return e;
        });
        let item = list[0] || {};
        let id = item.id;
        if (id) {
          if (item.children && item.children.length) {
            item = item.children[0];
            id = item.id;
          }
          this.queryData.buildingLayoutId =
            this.queryData.buildingLayoutId || id;
        }
        this.houseLayoutTreeData = list;
        this.getTreeNameById();
      }
    },
    async houseFilter() {
      let { buildingLayoutId } = this.queryData;
      if (!buildingLayoutId) {
        return;
      }
      let query = {
        buildingLayoutId
      };
      const res = await houseFilter(query);
      if (res) {
        const { openQuotationList, typeList, salesStatusList } = res;
        this.saleOptions.list = this.filterList(salesStatusList, {
          hasColor: true,
          hasName2: true
        });
        this.carOptions.list = this.filterList(typeList, {
          hasName2: true
        });
        this.activityOptions.list = this.filterList(openQuotationList, {
          hasNum: true
        });
        this.setActivetyId();
      }
    },
    filterList(list, { hasColor, hasName2, hasNum }) {
      let arr = [];
      list = list || [];
      list.forEach(e => {
        let item = {
          id: e.id,
          name: e.displayName
        };
        if (hasColor) {
          let colorObj = this.openingCarStateObj[e.id] || {};
          item.color = colorObj.color || "";
        }
        if (hasName2) {
          item.name2 = e.count;
        }
        if (hasNum) {
          item.num = `${e.count}/${e.totalCount}`;
        }
        arr.push(item);
      });
      return arr;
    },
    getChecked(e, name) {
      this.queryData[name] = e;
      this.setActivetyId();
    },
    setActivetyId() {
      let id = "";
      let checkList = this.queryData.openQuotationIdList;
      let activeList = this.activityOptions.list;
      if (checkList.length == 1) {
        id = checkList[0];
      } else if (activeList.length == 1) {
        id = activeList[0].id;
      }
      this.activetyId = id;
    }
  }
};
</script>

<style lang="scss" scoped>
.house {
  height: calc(100vh - 110px);
}
.openingMenu {
  height: 100%;
  overflow: auto;
  width: 290px;
  min-width: 290px;
  ::v-deep {
    .el-button--medium {
      padding: 10px;
    }
  }
}
</style>
