<template>
  <div class="info y3">
    <div>
      <div class="y aic" :class="{ color9: !buildingInfo.available }">
        <p class="fs20 bold lh40">
          {{ buildingInfo.buildingName }}
          {{ buildingInfo.unitName && `-${buildingInfo.unitName}单元` }}
          {{ buildingInfo.floorName && `-${buildingInfo.floorName}楼` }}
          {{ buildingInfo.houseNo && `-${buildingInfo.houseNo}号房` }}
        </p>
        <p>{{ buildingInfo.available ? "上架" : "下架" }}中</p>
      </div>
      <div
        class="state y3"
        :style="{ 'background-color': carStateObj.bgColor }"
      >
        <div class="fs16 bold x3 aic">
          <p
            class="flex1"
            :style="{
              color: carStateObj.color
            }"
          >
            {{ carStateObj.name }}
          </p>
          <p class="flex1 x1">
            {{
              buildingInfo.discountBeforePrice
                ? `${buildingInfo.discountBeforePrice}万元`
                : `价格待定`
            }}
          </p>
          <p class="red flex1 x2">
            {{
              buildingInfo.discountAfterPrice
                ? `${buildingInfo.discountAfterPrice}万元`
                : `价格待定`
            }}
          </p>
        </div>
        <div class="fs14 color6 x3 aic">
          <p class="flex1">销售状态</p>
          <p class="flex1 x1">优惠前总价</p>
          <p class="flex1 x2">优惠后总价</p>
        </div>
      </div>
      <div>
        <div v-if="promotions.length">
          <p class="bold lh40">营销活动</p>
          <div
            class="pb16 bdb2"
            v-for="(item, index) in promotions"
            :key="index"
          >
            <div class="x3 aic h28">
              <p class="color9">认购总额</p>
              <div class="x aic h28">
                <p class="red">¥</p>
                <p class="fs20 red">
                  {{
                    item.subscriptionTotalPrice
                      ? `${item.subscriptionTotalPrice}万元`
                      : `价格待定`
                  }}
                </p>
              </div>
            </div>
            <div class="x3 aic h28">
              <p class="color9">优惠活动</p>
              <div class="x aic h28">
                <img
                  src="../../../../assets/icon_libao_xiao.png"
                  class="icon20 mr10"
                />
                <p class="red">{{ item.promotionalName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="bold lh40">基本信息</p>
          <div class="pb16 bdb2" v-if="buildingInfo.id">
            <div class="x3 aic h28" v-if="buildingInfo.openQuotationName">
              <p class="color9">开盘活动:</p>
              <p>{{ buildingInfo.openQuotationName }}</p>
            </div>
            <div class="x3 aic h28" v-if="buildingInfo.houseTypeName">
              <p class="color9">房源类型:</p>
              <p>{{ buildingInfo.houseTypeName }}</p>
            </div>
            <div class="x3 aic h28" v-if="buildingInfo.bedRoomQuantity">
              <p class="color9">居室厅:</p>
              <p>
                {{ buildingInfo.bedRoomQuantity }}室{{
                  buildingInfo.livingRoomQuantity
                }}厅{{ buildingInfo.restRoomQuantity }}卫
              </p>
            </div>
            <div class="x3 aic h28" v-if="buildingInfo.houseLayoutName">
              <p class="color9">关联户型:</p>
              <p>{{ buildingInfo.houseLayoutName }}</p>
            </div>
            <div class="x3 aic h28" v-if="buildingInfo.remodelling">
              <p class="color9">装修情况:</p>
              <p>{{ buildingInfo.remodelling }}</p>
            </div>
            <div class="x3 aic h28" v-if="buildingInfo.buildingArea">
              <p class="color9">建筑面积:</p>
              <p>{{ buildingInfo.buildingArea }}㎡</p>
            </div>
            <div class="x3 aic h28" v-if="buildingInfo.actualArea">
              <p class="color9">实得面积:</p>
              <p>{{ buildingInfo.actualArea }}㎡</p>
            </div>
            <div class="x3">
              <p class="color9 lh28">备注信息:</p>
              <p class="w240 lh28">{{ buildingInfo.description }}</p>
            </div>
          </div>
          <div class="pb16 bdb2">
            <div class="x3 aic h40">
              <p class="color9">收藏用户</p>
              <p>{{ collectionUsers.length }}个</p>
            </div>
            <div class="user-list">
              <div
                class="user-cell"
                style="cursor:pointer"
                v-for="(item, index) in collectionUsers"
                :key="index"
              >
                <el-popover placement="top-start" width="220" trigger="hover">
                  <div>
                    <p>认购客户：{{ item.name }}</p>
                    <p>联系电话：{{ item.cellphone | formatPhone }}</p>
                  </div>
                  <img
                    slot="reference"
                    class="user-cell"
                    :src="item.avatarUrl"
                  />
                </el-popover>
              </div>
            </div>
          </div>
        </div>
        <div v-if="subscriptionParkingBookingOrder.id">
          <p class="bold lh40">认购订单</p>
          <div class="pb16 bdb2" v-if="subscriptionParkingBookingOrder.id">
            <div class="x3 aic h28">
              <p class="color9">认购单号</p>
              <p>
                <svg-icon
                  icon-class="copy"
                  class="copy-icon pointer"
                  @click="copyText(subscriptionParkingBookingOrder.orderNo)"
                ></svg-icon>
                {{ subscriptionParkingBookingOrder.orderNo }}
              </p>
            </div>
            <div class="x3 aic h28">
              <p class="color9">认购总额</p>
              <div class="x aic h28">
                <p class="red">¥</p>
                <p
                  class="fs20 red bold"
                  v-if="subscriptionParkingBookingOrder.orderTotalPrice"
                >
                  {{ subscriptionParkingBookingOrder.orderTotalPrice }}万元
                </p>
                <p class="fs20 red bold" v-else>价格待定</p>
              </div>
            </div>
            <div class="x3 aic h28">
              <p class="color9">优惠活动</p>
              <p class="red">
                {{
                  subscriptionParkingBookingOrder.giftBagName ||
                    (subscriptionParkingBookingOrder.orderTotalPriceDesc ==
                    "认购总额"
                      ? "无"
                      : subscriptionParkingBookingOrder.orderTotalPriceDesc)
                }}
              </p>
            </div>
          </div>
          <div class="pb16 bdb2 mt8" v-if="subscriptionParkingBookingOrder.id">
            <div class="x3 aic h28">
              <p class="color9">认购金</p>
              <p class="red">
                ¥{{ subscriptionParkingBookingOrder.orderPrice | toThousands }}
              </p>
            </div>
            <div class="x3 aic h28">
              <p class="color9">支付方式</p>
              <p>{{ subscriptionParkingBookingOrder.orderWayDesc }}</p>
            </div>
            <div class="x3 aic h28">
              <p class="color9">认购时间</p>
              <p>{{ subscriptionParkingBookingOrder.inDate }}</p>
            </div>
          </div>
          <div class="pb16 bdb2 mt8">
            <div class="x3 aic h28">
              <p class="color9">认购人</p>
              <p>{{ subscriptionParkingBookingOrder.customerName }}</p>
            </div>
            <div class="x3 aic h28">
              <p class="color9">身份证号</p>
              <p>{{ subscriptionParkingBookingOrder.customerIdcard }}</p>
            </div>
            <div class="x3 aic h28">
              <p class="color9">手机号码</p>
              <p>{{ subscriptionParkingBookingOrder.customerPhone }}</p>
            </div>
          </div>
        </div>
        <div v-if="bidTargetInfo.biddingAmount">
          <p class="bold lh40">正在竞拍</p>
          <div class="pb16 bdb2">
            <div class="x3 aic h28" v-if="bidTargetInfo.biddingAmount">
              <p class="color9">起拍价:</p>
              <p>¥{{ bidTargetInfo.biddingAmount | toThousands }}</p>
            </div>
            <div class="x3 aic h28" v-if="bidTargetInfo.biddingStepAmount">
              <p class="color9">加价幅度:</p>
              <p>¥{{ bidTargetInfo.biddingStepAmount | toThousands }}</p>
            </div>
            <div class="x3 aic h28" v-if="bidTargetInfo.delay">
              <p class="color9">延时周期:</p>
              <p>{{ bidTargetInfo.delay }}分钟</p>
            </div>
            <div class="x3 aic h28" v-if="bidTargetInfo.ensureAmount">
              <p class="color9">保证金:</p>
              <p>¥{{ bidTargetInfo.ensureAmount | toThousands }}</p>
            </div>
            <div class="x3 aic h28" v-if="bidTargetInfo.startDate">
              <p class="color9">开始时间:</p>
              <p>{{ bidTargetInfo.startDate }}</p>
            </div>
            <div class="x3 aic h28" v-if="bidTargetInfo.endDelayDate">
              <p class="color9">结束时间:</p>
              <p>{{ bidTargetInfo.endDelayDate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="x aic mt24">
      <el-button class="flex1" size="medium" @click="houseBatchAvailable">
        {{ buildingInfo.available ? "下架" : "上架" }}
      </el-button>
      <el-button class="flex1" type="primary" size="medium" @click="openEdit">
        编辑房源
      </el-button>
    </div>
  </div>
</template>

<script>
import { enumeration } from "@/config";
import { houseDetailInfo, houseBatchAvailable } from "@/api/opening";
import { copyText } from "@/utils";

export default {
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      openingCarStateObj: enumeration.openingCarStateObj,
      buildingInfo: {},
      subscriptionParkingBookingOrder: {},
      collectionUsers: [],
      promotions: [],
      bidTargetInfo: {}
    };
  },
  computed: {
    carStateObj() {
      const { openingCarStateObj, buildingInfo } = this;
      return openingCarStateObj[buildingInfo.salesStatus] || {};
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    copyText,
    openEdit() {
      this.$emit("openEdit");
    },
    async getDetail() {
      const { id } = this;
      if (!id) {
        return;
      }
      const res = await houseDetailInfo(id);
      if (res) {
        const {
          buildingInfo,
          subscriptionParkingBookingOrder,
          collectionUsers,
          promotions,
          bidTargetInfo
        } = res;
        this.buildingInfo = buildingInfo || {};
        this.subscriptionParkingBookingOrder =
          subscriptionParkingBookingOrder || {};
        this.collectionUsers = collectionUsers || [];
        this.promotions = promotions || [];
        this.bidTargetInfo = bidTargetInfo || {};
      }
    },
    async houseBatchAvailable() {
      let params = {
        houseIdList: [this.buildingInfo.id],
        available: !this.buildingInfo.available
      };
      const res = await houseBatchAvailable(params);
      if (res) {
        this.$showSuccess("操作成功");
        this.getDetail();
        this.$emit("refresh");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.copy-icon {
  margin-right: 10px;
  cursor: pointer;
  &:active {
    opacity: 0.75;
  }
}
.info {
  padding: 20px;
  min-height: 100vh;
  .w240 {
    width: 240px;
    text-align: right;
  }
  .state {
    margin-top: 36px;
    height: 80px;
    border-radius: 3px;
    padding: 20px;
  }
  .user-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .user-cell {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
</style>
